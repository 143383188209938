import React from 'react'
import PropTypes from 'prop-types'
import { useVT } from 'virtualizedtableforantd4'
import moment from 'moment'

import dayjs from "dayjs";
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import {Modal, Spin} from 'antd';

import { useState } from "react";
import { getStartEndDateForProject, initTasks } from "./Data";
import { ViewChanger } from "./Components/ViewChanger";
import ProjectModal from '../../../../Modals/Project';
import SubcontractorModal from "../../../../Modals/Subcontractor";

function onClick (task)
{
    console.log(task);
}

//function g() {
const g = props => {

    const {
        periods,
        rows,
        fetching,
        onClickProject,
        onClickCell,
        ganttSettingDuration,
        showProjectModal,
        showSubcontractorModal,
        onClickEditSubcontractor,
    } = props

    if (!rows.length > 0)
        return (<div><br /><br /><center>No results</center><br /><br /></div>);

    const [view, setView] = useState(ViewMode.Day);
    const [tasks, setTasks] = useState(initTasks(rows));
    const [isChecked, setIsChecked] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    let columnWidth = 65;
    if (view === ViewMode.Month) {
        columnWidth = 300;
    } else if (view === ViewMode.Week) {
        columnWidth = 250;
    }

    const handleTaskChange = (task) => {
        console.log("On date change Id:" + task.id);
        let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
        if (task.project) {
            const [start, end] = getStartEndDateForProject(newTasks, task.project);
            const project =
                newTasks[newTasks.findIndex((t) => t.id === task.project)];
            if (
                project.start.getTime() !== start.getTime() ||
                project.end.getTime() !== end.getTime()
            ) {
                const changedProject = { ...project, start, end };
                newTasks = newTasks.map((t) =>
                    t.id === task.project ? changedProject : t
                );
            }
        }
        setTasks(newTasks);
    };

    const handleTaskDelete = (task) => {
        /*const conf = window.confirm("Are you sure about " + task.name + " ?");
        if (conf) {
            setTasks(tasks.filter((t) => t.id !== task.id));
        }
        return conf;*/
    };

    const handleProgressChange = async (task) => {
        /*
        setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
        */
    };

    const handleDblClick = (task) => {

        if (task.type == 'project')
            onClickProject(task.project);

        if (task.subType == 'subby')
        {
            onClickEditSubcontractor(task.subcontractor);
        }

    };

    const setModalData = () => {
        document.getElementById('modal-content').innerHTML = document.getElementById('modal-template').innerHTML;
    }

    const handleSelect = (task, isSelected) => {
        console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));

    };

    const handleExpanderClick = (task) => {
        setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
        console.log("On expander click Id:" + task.id);
    };

    return (
        <div>
            <ViewChanger
                onViewModeChange={(viewMode) => setView(viewMode)}
                onViewListChange={setIsChecked}
                isChecked={isChecked}

            />
            <Gantt
                tasks={tasks}
                viewMode={view}
                onDateChange={handleTaskChange}
                onDelete={handleTaskDelete}
                onProgressChange={handleProgressChange}
                onDoubleClick={handleDblClick}
                onSelect={handleSelect}
                onExpanderClick={handleExpanderClick}
                listCellWidth={isChecked ? "155px" : ""}
                columnWidth={columnWidth}
            />
            <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} >
                <div id="modal-content">

                </div>
            </Modal>

            {showProjectModal && (
                <ProjectModal
                    defaultTab='totals'
                    project={project}
                    onClose={onCloseProjectModal}
                    didUpdate={didUpdateProject}
                    didDelete={didDeleteProject}
                />
            )}

            {showSubcontractorModal && (
                <SubcontractorModal
                    onClose={onCloseSubcontractorModal}
                    didUpdate={didUpdateSubcontractor}
                    didDelete={didDeleteSubcontractor}
                    subcontractor={subcontractor}
                />
            )}

        </div>

    );
}

export default g;
