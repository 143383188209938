import { connect } from 'react-redux'
import ProjectTable from './Gantt'

import onClickProject from './Handlers/onClickProject'
import onClickSubcontractor from "./Handlers/onClickSubcontractor";
import onClickCell from './Handlers/onClickCell'
import onClickEditSubcontractor from './Handlers/onClickEditSubcontractor';

const mapStateToProps = (state) => ({
  periods: state.screens.Planner.data.periods,
  rows: state.screens.Planner.data.rows,
  fetching: state.screens.Planner.fetching,
  showSubcontractorModal: state.screens.Planner.showSubcontractorModal,
})

const actionCreators = {
  onClickProject,
  onClickCell,
  onClickSubcontractor,
  onClickEditSubcontractor,
}

export default connect(mapStateToProps, actionCreators)(ProjectTable)
