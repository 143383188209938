import React from 'react'
import PropTypes from 'prop-types'
import { Card, Dropdown, Menu, Input, Select, DatePicker, Radio, Button, Tag, Tooltip } from 'antd'
import {
  BellFilled,
  BellOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  ClockCircleFilled,
  ClockCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  CloudDownloadOutlined,
  ControlFilled,
  ControlOutlined,
  EnvironmentOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  EyeInvisibleFilled,
  EyeInvisibleOutlined,
  FileExclamationFilled,
  FileExclamationOutlined,
  FilterFilled,
  FilterOutlined,
  FlagFilled,
  FlagOutlined,
  FormatPainterFilled,
  FormatPainterOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  LeftOutlined,
  LinkOutlined,
  MinusOutlined,
  PoundCircleFilled,
  PoundCircleOutlined,
  RetweetOutlined,
  RightOutlined,
  ScheduleFilled,
  ScheduleOutlined,
  UserOutlined,
  WarningFilled,
  WarningOutlined,
  PicCenterOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import styles from './styles.css'
import {ViewMode} from "gantt-task-react";

const Spacer = () => <div style={{ width: 10 }} />

const blue = '#1890ff'

export default class Controls extends React.PureComponent {

  static propTypes = {
    users: PropTypes.array.isRequired,
    projectStatuses: PropTypes.array.isRequired,
    projectPhases: PropTypes.array.isRequired,
    controls: PropTypes.object.isRequired,
    filterApplied: PropTypes.bool.isRequired,
    dateRange: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    onChangeControls: PropTypes.func.isRequired,
    onSubmitSearch: PropTypes.func.isRequired,
    onClickAllFilters: PropTypes.func.isRequired,
    onClickSubcontractors: PropTypes.func.isRequired,
    onClickExport: PropTypes.func.isRequired,
    onSelectDate: PropTypes.func.isRequired,
  }

  static defaultProps = {
    startDate: null,
  }

  state = {
    showDateSelector: false,
  }

  render() {

    const {
      users,
      projectStatuses,
      projectPhases,
      controls,
      filterApplied,
      dateRange,
      startDate,
      onChangeControls,
      onSubmitSearch,
      onClickAllFilters,
      onClickSubcontractors,
      onClickExport,
      onSelectDate,
    } = this.props

      console.log(controls);

    return (

      <Card>

        <div className={styles.wrapper}>
          <div className={styles.left}>

            <Radio.Group
              value={controls.yAxis}
              onChange={e => onChangeControls('yAxis', e.target.value)}
            >
              <Tooltip title='View by Projects'>
                <Radio.Button key='projects' value='projects'><EnvironmentOutlined /></Radio.Button>
              </Tooltip>
              <Tooltip title='View by Subcontractors'>
                <Radio.Button key='subcontractors' value='subcontractors'><UserOutlined /></Radio.Button>
              </Tooltip>
                <Tooltip title='Gantt'>
                    <Radio.Button key='gantt' value='gantt'><PicCenterOutlined /></Radio.Button>
                </Tooltip>
            </Radio.Group>

            {(controls.yAxis === 'projects' || controls.yAxis === 'gantt') && (

              <React.Fragment>

                <Spacer />

                <Input.Search
                  value={controls.projectSearch}
                  onChange={e => onChangeControls('projectSearch', e.target.value)}
                  onSearch={onSubmitSearch}
                  placeholder='Search...'
                  style={{ width: 180 }}
                />

                <Spacer />

                <Select
                  value={controls.contractsManagerId}
                  onChange={value => onChangeControls('contractsManagerId', value)}
                  mode='multiple'
                  showSearch
                  optionFilterProp='children'
                  allowClear
                  placeholder='Manager'
                  style={{ width: 180 }}
                >
                  <Select.OptGroup label='Manager'>
                    <Select.Option key='null' value='null'>
                      <div style={{ fontStyle: 'italic' }}>No Manager</div>
                    </Select.Option>
                    {users.map(user => (
                      <Select.Option key={user.id} value={user.id}>
                        {user.full_name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>

                <Spacer />

                <Select
                  value={controls.statusKey}
                  onChange={value => onChangeControls('statusKey', value)}
                  mode='multiple'
                  placeholder='Status'
                  style={{ width: 180 }}
                >
                  <Select.OptGroup label='Status'>
                    {projectStatuses.map(projectStatus => (
                      <Select.Option key={projectStatus.key} value={projectStatus.key}>
                        {projectStatus.title}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>

                <Spacer />

                <Select
                  value={controls.phaseKey}
                  onChange={value => onChangeControls('phaseKey', value)}
                  mode='multiple'
                  allowClear
                  placeholder='Phase'
                  style={{ width: 180 }}
                >
                  <Select.OptGroup label='Phase'>
                    {projectPhases.map(projectPhase => (
                      <Select.Option key={projectPhase.key} value={projectPhase.key}>
                        {projectPhase.title}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>

              </React.Fragment>

            )}

            {controls.yAxis === 'subcontractors' && (

              <React.Fragment>

                <Spacer />

                <Input.Search
                  value={controls.subcontractorSearch}
                  onChange={e => onChangeControls('subcontractorSearch', e.target.value)}
                  onSearch={onSubmitSearch}
                  placeholder='Search...'
                  style={{ width: 180 }}
                />

                <Spacer />

                <Select
                  value={controls.cardType}
                  onChange={value => onChangeControls('cardType', value)}
                  mode='multiple'
                  showSearch
                  optionFilterProp='children'
                  allowClear
                  placeholder='Card Type'
                  style={{ width: 180 }}
                >
                  <Select.OptGroup label='Card Type'>
                    {['Red', 'Blue', 'Green', 'White', 'Gold', 'Black'].map(cardType => (
                      <Select.Option key={cardType} value={cardType}>
                        <Tag color={cardType !== 'White' && cardType}>
                          {cardType}
                        </Tag>
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>

              </React.Fragment>

            )}

            <Spacer />

            <Tooltip title='All Filters'>
              {filterApplied ? (
                <FilterFilled onClick={onClickAllFilters} className={styles.icon} style={{ color: blue }} />
              ) : (
                <FilterOutlined onClick={onClickAllFilters} className={styles.icon} style={{ color: blue }} />
              )}
            </Tooltip>

            {controls.yAxis === 'projects' && (

              <React.Fragment>

                <Spacer />

                <Tooltip title='Subcontractors'>
                  <UserOutlined onClick={onClickSubcontractors} className={styles.icon} style={{ color: blue }} />
                </Tooltip>

              </React.Fragment>

            )}

            {controls.yAxis === 'projects' && !controls.shrink && (

              <React.Fragment>

                <Spacer />

                <Tooltip title={controls.summary ? 'Show Schedule' : 'Show Summary'}>
                  {controls.summary ? (
                    <ClockCircleFilled onClick={() => onChangeControls('summary', !controls.summary)} className={styles.icon} style={{ color: blue }} />
                  ) : (
                    <ClockCircleOutlined onClick={() => onChangeControls('summary', !controls.summary)} className={styles.icon} style={{ color: blue }} />
                  )}
                </Tooltip>

              </React.Fragment>

            )}

            {controls.yAxis === 'projects' && !controls.summary && (

              <React.Fragment>

                <Spacer />

                <Tooltip title={controls.shrink ? 'Expand Table' : 'Shrink Table'}>
                  {controls.shrink ? (
                    <FullscreenOutlined onClick={() => onChangeControls('shrink', !controls.shrink)} className={styles.icon} style={{ color: blue }} />
                  ) : (
                    <FullscreenExitOutlined onClick={() => onChangeControls('shrink', !controls.shrink)} className={styles.icon} style={{ color: blue }} />
                  )}
                </Tooltip>

              </React.Fragment>

            )}

            <Spacer />

            <Tooltip title={controls.hideEmpty ? 'Hiding Empty' : 'Hide Empty'}>
              {controls.hideEmpty ? (
                <EyeInvisibleFilled onClick={() => onChangeControls('hideEmpty', !controls.hideEmpty)} className={styles.icon} style={{ color: blue }} />
              ) : (
                <EyeInvisibleOutlined onClick={() => onChangeControls('hideEmpty', !controls.hideEmpty)} className={styles.icon} style={{ color: blue }} />
              )}
            </Tooltip>

            {controls.yAxis === 'projects' && (

              <React.Fragment>

                <Spacer />

                <Tooltip title={controls.omitted ? 'Showing Unsubscribed' : 'Unsubscribed Only'}>
                  {controls.omitted ? (
                    <BellFilled onClick={() => onChangeControls('omitted', !controls.omitted)} className={styles.icon} style={{ color: blue }} />
                  ) : (
                    <BellOutlined onClick={() => onChangeControls('omitted', !controls.omitted)} className={styles.icon} style={{ color: blue }} />
                  )}
                </Tooltip>

                <Spacer />

                <Tooltip title='Flags'>
                  <Dropdown
                    trigger={['click']}
                    overlay={(
                      <Menu onClick={({ key }) => onChangeControls(key, !controls[key])}>
                        <Menu.Item
                          key='inductionRequired'
                          icon={controls.inductionRequired ? (
                            <CheckCircleFilled style={{ color: '#61c566' }} />
                          ) : (
                            <CheckCircleOutlined style={{ color: '#61c566' }} />
                          )}
                        >
                          Induction Required
                        </Menu.Item>
                        <Menu.Item
                          key='weeklyReturns'
                          icon={controls.weeklyReturns ? (
                            <CheckCircleFilled style={{ color: '#61c566' }} />
                          ) : (
                            <CheckCircleOutlined style={{ color: '#61c566' }} />
                          )}
                        >
                          Weekly Returns
                        </Menu.Item>
                        <Menu.Item
                          key='termsAndConditions'
                          icon={controls.termsAndConditions ? (
                            <CheckCircleFilled style={{ color: '#61c566' }} />
                          ) : (
                            <CheckCircleOutlined style={{ color: '#61c566' }} />
                          )}
                        >
                          Client Terms & Conditions
                        </Menu.Item>
                        <Menu.Item
                          key='noValuationThisMonth'
                          icon={controls.noValuationThisMonth ? (
                            <ScheduleFilled style={{ color: 'orange' }} />
                          ) : (
                            <ScheduleOutlined style={{ color: 'orange' }} />
                          )}
                        >
                          No Valuation This Month
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    {controls.inductionRequired || controls.weeklyReturns || controls.termsAndConditions || controls.noValuationThisMonth ? (
                      <FlagFilled className={styles.icon} style={{ color: blue }} />
                    ) : (
                      <FlagOutlined className={styles.icon} style={{ color: blue }} />
                    )}
                  </Dropdown>
                </Tooltip>

                <Spacer />

                <Tooltip title='RAMS Filters'>
                  <Dropdown
                    trigger={['click']}
                    overlay={(
                      <Menu onClick={({ key }) => onChangeControls(key, !controls[key])}>
                        <Menu.Item
                          key='missingRams'
                          icon={controls.missingRams ? (
                            <CloseCircleFilled style={{ color: 'red' }} />
                          ) : (
                            <CloseCircleOutlined style={{ color: 'red' }} />
                          )}
                        >
                          RAMS Missing
                        </Menu.Item>
                        <Menu.Item
                          key='expiringRams'
                          icon={controls.expiringRams ? (
                            <ExclamationCircleFilled style={{ color: 'orange' }} />
                          ) : (
                            <ExclamationCircleOutlined style={{ color: 'orange' }} />
                          )}
                        >
                          RAMS Expiring
                        </Menu.Item>
                        <Menu.Item
                          key='expiredRams'
                          icon={controls.expiredRams ? (
                            <WarningFilled style={{ color: 'red' }} />
                          ) : (
                            <WarningOutlined style={{ color: 'red' }} />
                          )}
                        >
                          RAMS Expired
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    {controls.missingRams || controls.expiringRams || controls.expiredRams ? (
                      <FileExclamationFilled className={styles.icon} style={{ color: blue }} />
                    ) : (
                      <FileExclamationOutlined className={styles.icon} style={{ color: blue }} />
                    )}
                  </Dropdown>
                </Tooltip>

              </React.Fragment>

            )}

            {((controls.yAxis === 'projects' && !controls.summary) || controls.yAxis === 'subcontractors') && (

              <React.Fragment>

                <Spacer />

                <Tooltip title='Job Filters'>
                  <Dropdown
                    trigger={['click']}
                    overlay={(
                      <Menu onClick={({ key }) => onChangeControls(key, !controls[key])}>
                        <Menu.Item
                          key='unconfirmedOrRequired'
                          icon={controls.unconfirmedOrRequired ? (
                            <FormatPainterFilled style={{ color: 'red' }} />
                          ) : (
                            <FormatPainterOutlined style={{ color: 'red' }} />
                          )}
                        >
                          Unconfirmed / Required
                        </Menu.Item>
                        <Menu.Item
                          key='unattended'
                          icon={controls.unattended ? (
                            <FormatPainterFilled style={{ color: '#9e9e9e' }} />
                          ) : (
                            <FormatPainterOutlined style={{ color: '#9e9e9e' }} />
                          )}
                        >
                          Unattended
                        </Menu.Item>
                        <Menu.Item
                          key='unavailableOrAbsent'
                          icon={controls.unavailableOrAbsent ? (
                            <FormatPainterFilled style={{ color: '#3c3b3b' }} />
                          ) : (
                            <FormatPainterOutlined style={{ color: '#3c3b3b' }} />
                          )}
                        >
                          Unavailable / Absent
                        </Menu.Item>
                        <Menu.Item
                          key='duplicated'
                          icon={controls.duplicated ? (
                            <FormatPainterFilled style={{ color: '#A020F0' }} />
                          ) : (
                            <FormatPainterOutlined style={{ color: '#A020F0' }} />
                          )}
                        >
                          Duplicated
                        </Menu.Item>
                        <Menu.Item
                          key='unexpected'
                          icon={controls.unexpected ? (
                            <FormatPainterFilled style={{ color: 'orange' }} />
                          ) : (
                            <FormatPainterOutlined style={{ color: 'orange' }} />
                          )}
                        >
                          Unexpected
                        </Menu.Item>
                        <Menu.Item
                          key='attended'
                          icon={controls.attended ? (
                            <FormatPainterFilled style={{ color: 'green' }} />
                          ) : (
                            <FormatPainterOutlined style={{ color: 'green' }} />
                          )}
                        >
                          Attended
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    {controls.unconfirmedOrRequired || controls.unattended || controls.unavailableOrAbsent || controls.duplicated || controls.unexpected || controls.attended ? (
                      <FormatPainterFilled className={styles.icon} style={{ color: blue }} />
                    ) : (
                      <FormatPainterOutlined className={styles.icon} style={{ color: blue }} />
                    )}
                  </Dropdown>
                </Tooltip>

                <Spacer />

                <Tooltip title='Misc'>
                  <Dropdown
                    trigger={['click']}
                    overlay={(
                      <Menu onClick={({ key }) => onChangeControls(key, !controls[key])}>
                        <Menu.Item
                          key='alerts'
                          icon={controls.alerts ? (
                            <ExclamationCircleFilled style={{ color: '#faad14' }} />
                          ) : (
                            <ExclamationCircleOutlined style={{ color: '#faad14' }} />
                          )}
                        >
                          Alerts
                        </Menu.Item>
                        <Menu.Item
                          key='switched'
                          icon={controls.switched ? (
                            <RetweetOutlined />
                          ) : (
                            <RetweetOutlined style={{ opacity: 0.25 }} />
                          )}
                        >
                          Switched
                        </Menu.Item>
                        <Menu.Item
                          key='priceWork'
                          icon={controls.priceWork ? (
                            <PoundCircleFilled style={{ color: '#cfb000' }} />
                          ) : (
                            <PoundCircleOutlined style={{ color: '#cfb000' }} />
                          )}
                        >
                          Price Work
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    {controls.alerts || controls.switched || controls.priceWork ? (
                      <ControlFilled className={styles.icon} style={{ color: blue }} />
                    ) : (
                      <ControlOutlined className={styles.icon} style={{ color: blue }} />
                    )}
                  </Dropdown>
                </Tooltip>

              </React.Fragment>

            )}

            <Spacer />

              {(controls.yAxis !== 'gantt') && (
                  <React.Fragment>

                    <Tooltip title='Google Maps'>
                        <LinkOutlined onClick={() => window.open('https://www.google.com/maps/d/edit?mid=10N5C8SHFy0HGagFytrgakbd7WgmXRMWS&usp=sharing')} className={styles.icon} style={{ color: blue }} />
                    </Tooltip>

                    <Spacer />

                    <Tooltip title='Export'>
                        <CloudDownloadOutlined onClick={onClickExport} className={styles.icon} style={{ color: blue }} />
                    </Tooltip>
                  </React.Fragment>

              )}

          </div>
        </div>

        <div>
          <div className={styles.right}>

            {controls.yAxis !== 'gantt' && (
                <div
                  role='presentation'
                  onClick={() => this.setState({ showDateSelector: true })}
                  style={{ cursor: 'pointer' }}
                >
                    <nobr> {dateRange} </nobr>
                </div>
            )}

            {(this.state.showDateSelector && controls.yAxis !== 'gantt') && (
              <DatePicker
                value={moment(startDate)}
                onChange={value => {
                  if (value) onSelectDate(value)
                  this.setState({ showDateSelector: false })
                }}
                autoFocus
                open
                onBlur={() => this.setState({ showDateSelector: false })}
                style={{ position: 'absolute', opacity: 0 }}
              />
            )}

            <Spacer />

           {controls.yAxis !== 'gantt' && (

                <Button.Group>
                  <Button onClick={() => onChangeControls('dec-period-index')}>
                    <LeftOutlined />
                  </Button>
                  <Button onClick={() => onChangeControls('reset-period-index')}>
                    <MinusOutlined />
                  </Button>
                  <Button onClick={() => onChangeControls('inc-period-index')}>
                    <RightOutlined />
                  </Button>
                </Button.Group>

           )}

          </div>
        </div>

      </Card>

    )

  }

}
