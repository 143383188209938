
import { createAction, handleActions } from 'redux-actions';

const setShowSubcontractorModal = createAction("SCREENS/PLANNER/SET_SHOW_SUBCONTRACTOR_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowSubcontractorModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowSubcontractorModal, reducer };
