// @flow
import * as React from 'react';
import { Tabs, Modal, Button } from 'antd';
import Form from './Form';
import ClientModal from '../Client';
import ClientSiteModal from '../ClientSite';
import ClientContactModal from '../ClientContact';
import TargetCosts from "../../Screens/ProjectView/Components/TargetCosts";
import ProjectTotals from './Components/ProjectTotals';
import Footer from "../../Screens/Planner/Components/Footer";
import PropTypes from "prop-types";

type Props = {
  project: ?any,
  planner: ?any,
  clients: [],
  clientContacts: [],
  clientSites: [],
  users: [],
  projectPhases: [],
  project: {},
  clientId: any,
  submitting: boolean,
  showNewClientContactButton: boolean,
  showNewClientSiteButton: boolean,
  showClientModal: boolean,
  showClientSiteModal: boolean,
  showClientContactModal: boolean,

  componentWillMount: (props: any) => void,
  componentWillUnmount: () => void,
  didCreateClient: () => void,
  didCreateClientSite: () => void,
  didCreateClientContact: () => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onClickDelete: () => void,
  onChangeClient: () => void,
  onClickNewClient: () => void,
  onClickNewClientSite: () => void,
  onClickNewClientContact: () => void,
  onCloseClientModal: () => void,
  onCloseClientSiteModal: () => void,
  onCloseClientContactModal: () => void,
    defaultTab: string,
    projectTotals: PropTypes.object.isRequired,

}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      project,
      planner,
      clients,
      clientContacts,
      clientSites,
      users,
      projectPhases,
      clientId,
      submitting,
      showNewClientContactButton,
      showNewClientSiteButton,
      showClientModal,
      showClientSiteModal,
      showClientContactModal,

      didCreateClient,
      didCreateClientSite,
      didCreateClientContact,
      onClickCancel,
      onClickSubmit,
      onClickDelete,
      onChangeClient,
      onClickNewClient,
      onClickNewClientSite,
      onClickNewClientContact,
      onCloseClientModal,
      onCloseClientSiteModal,
      onCloseClientContactModal,

        defaultTab,
        projectTotals,

    } = this.props;

    const footer = []

    if (project) footer.push(
      <Button key='delete' type='danger' onClick={onClickDelete} style={{ float: 'left' }}>
        Delete
      </Button>,
    )

    footer.push(
      <Button key='cancel' onClick={() => onClickCancel()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        {project ? 'Update' : 'Create'}
      </Button>,
    )

    return (

      <Modal
        title={project ? 'Edit Project' : 'New Project'}
        visible
        footer={footer}
        onCancel={onClickCancel}
        width={600}
      >

        <Tabs
          defaultActiveKey={defaultTab}
          animated={false}
          type="card"
        >

            <Tabs.TabPane tab="Edit Form" key="edit">
                <Form
                  project={project}
                  planner={planner}
                  clients={clients}
                  clientSites={clientSites}
                  clientContacts={clientContacts}
                  users={users}
                  projectPhases={projectPhases}
                  showNewClientContactButton={showNewClientContactButton}
                  showNewClientSiteButton={showNewClientSiteButton}
                  onChangeClient={onChangeClient}
                  onClickNewClient={onClickNewClient}
                  onClickNewClientSite={onClickNewClientSite}
                  onClickNewClientContact={onClickNewClientContact}
                />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Totals" key="totals">

                {projectTotals && (

                    <ProjectTotals
                    projectTotals={projectTotals}
                    />
                )}

            </Tabs.TabPane>

        </Tabs>

        {showClientModal && (
          <ClientModal
            onClose={onCloseClientModal}
            didCreate={didCreateClient}
          />
        )}

        {showClientSiteModal && (
          <ClientSiteModal
            onClose={onCloseClientSiteModal}
            didCreate={didCreateClientSite}
            clientId={clientId}
          />
        )}

        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didCreate={didCreateClientContact}
            clientId={clientId}
          />
        )}

      </Modal>
    )
  }
}
