import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import Component from './Component';

import selectClientContacts from './Selectors/selectClientContacts';
import selectClientSites from './Selectors/selectClientSites';
import selectClientId from './Selectors/selectClientId';
import selectShowNewClientContactButton from './Selectors/selectShowNewClientContactButton';
import selectShowNewClientSiteButton from './Selectors/selectShowNewClientSiteButton';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import didCreateClient from './Handlers/didCreateClient';
import didCreateClientSite from './Handlers/didCreateClientSite';
import didCreateClientContact from './Handlers/didCreateClientContact';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';
import onClickDelete from './Handlers/onClickDelete';
import onChangeClient from './Handlers/onChangeClient';
import onClickNewClient from './Handlers/onClickNewClient';
import onClickNewClientSite from './Handlers/onClickNewClientSite';
import onClickNewClientContact from './Handlers/onClickNewClientContact';
import onCloseClientModal from './Handlers/onCloseClientModal';
import onCloseClientSiteModal from './Handlers/onCloseClientSiteModal';
import onCloseClientContactModal from './Handlers/onCloseClientContactModal';

import { FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  clients: state.core.clients,
  clientContacts: selectClientContacts(state),
  clientSites: selectClientSites(state),
  users: state.core.users,
  projectPhases: state.core.projectPhases,
  clientId: selectClientId(state),
  submitting: isSubmitting(FORM_NAME)(state),
  showNewClientContactButton: selectShowNewClientContactButton(state),
  showNewClientSiteButton: selectShowNewClientSiteButton(state),
  showClientModal: state.modals.Project.showClientModal,
  showClientSiteModal: state.modals.Project.showClientSiteModal,
  showClientContactModal: state.modals.Project.showClientContactModal,
  projectTotals: state.modals.Project.projectTotals,

});

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  didCreateClient,
  didCreateClientSite,
  didCreateClientContact,
  onClickCancel,
  onClickSubmit,
  onClickDelete,
  onChangeClient,
  onClickNewClient,
  onClickNewClientSite,
  onClickNewClientContact,
  onCloseClientModal,
  onCloseClientSiteModal,
  onCloseClientContactModal
};

export default connect(mapStateToProps, actionCreators)(Component);
