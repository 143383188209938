import { combineReducers } from 'redux'

import { reducer as controls } from './State/controls'
import { reducer as data } from './State/data'
import { reducer as fetching } from './State/fetching'
import { reducer as controlsModal } from './State/controlsModal'
import { reducer as projectEditorModal } from './State/projectEditorModal'
import { reducer as projectCellModal } from './State/projectCellModal'
import { reducer as subcontractorEditorModal } from './State/subcontractorEditorModal'
import { reducer as subcontractorCellModal } from './State/subcontractorCellModal'
import { reducer as subcontractorDrawer } from './State/subcontractorDrawer'
import { reducer as keyDown } from './State/keyDown'
import { reducer as projectTotals } from './State/projectTotals';
import { reducer as showSubcontractorModal } from './State/showSubcontractorModal';

import ProjectCellModal from './Components/ProjectCellModal/reducer'
import SubcontractorCellModal from './Components/SubcontractorCellModal/reducer'
import SubcontractorPopover from './Components/SubcontractorPopover/reducer'
import SubcontractorDrawer from './Components/SubcontractorDrawer/reducer'

export default combineReducers({
  controls,
  data,
  fetching,
  controlsModal,
  projectEditorModal,
  projectCellModal,
  subcontractorEditorModal,
  subcontractorCellModal,
  subcontractorDrawer,
  keyDown,

  ProjectCellModal,
  SubcontractorCellModal,
  SubcontractorPopover,
  SubcontractorDrawer,
  projectTotals,

})
