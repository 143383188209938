import "gantt-task-react/dist/index.css"

import React from "react"
import { ViewMode } from "gantt-task-react"
import {Card, Button} from "antd";
import styles from '../styles.css'



export const ViewChanger = ({ onViewModeChange, onViewListChange, isChecked }) => {

    function toggleTaskCaption ()
    {
        if (document.getElementById('hideTasks').innerHTML == "<span>Hide Tasks</span>")
                document.getElementById('hideTasks').innerHTML = "<span>Show Tasks</span>";
        else    document.getElementById('hideTasks').innerHTML = "<span>Hide Tasks</span>";
    }

    return (

            <div className={styles.ganttChanger}>

            <Button.Group>

                <Button onClick={() => onViewModeChange(ViewMode.Day)}>
                    Day
                </Button>
                <Button onClick={() => onViewModeChange(ViewMode.Week)}>
                    Week
                </Button>
                <Button onClick={() => onViewModeChange(ViewMode.Month)}>
                    Month
                </Button>

            </Button.Group>

            <Button.Group>
                <Button id="hideTasks" onClick={() => {toggleTaskCaption(); onViewListChange(!isChecked);}}>
                    Hide Tasks
                </Button>
            </Button.Group>

        </div>

    )
}
