import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';
import {getProjectTotals} from "../../../HttpRequests/projects";
import {omit} from "lodash";
import {loadProjectTotals} from "../State/projectTotals";

export default () => async (dispatch) => {

  const { project } = getProps();

  if (project) {

    dispatch(initialize(FORM_NAME, project))

    const totalParams = {};

    totalParams['filter[profit_and_loss]'] = 1;
    totalParams['filter[id]'] = [project.id];

    const getProjectTotalsResponse = await getProjectTotals({ params: omit(totalParams, []) });
    dispatch(loadProjectTotals(getProjectTotalsResponse.data.data));

  } else {

    dispatch(initialize(FORM_NAME, { }))

  }

}
