// @flow

import * as React from 'react';
import numeral from 'numeral';
import moment from 'moment';

const styles = {

  footer: {
    paddingTop: 25,
    borderTop: '0px solid #DDD',
    paddingLeft: 20
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20
  },

  total: {
    marginRight: 40
  },

  label: {
    fontSize: 12,
    fontWeight: 500,
    color: '#999'
  },

  value: {
    fontSize: 15,
    fontWeight: 500,
    color: '#444'
  }

}

type Props = {
  projectTotals: any,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      projectTotals
    } = this.props;

    return (
      <div style={styles.footer}>
        <h2>Totals</h2>

        <div style={styles.row}>

          <div style={styles.total}>
            <div style={styles.label}>Value</div>
            <div style={styles.value}>
              {`${projectTotals.value_total < 0 ? '-' : ''}£${numeral(projectTotals.value_total * (projectTotals.value_total < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            &nbsp;
          </div>

          <div style={styles.total}>
            <div style={styles.label}>Completed</div>
            <div style={styles.value}>
              {`${projectTotals.value_completed < 0 ? '-' : ''}£${numeral(projectTotals.value_completed * (projectTotals.value_completed < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            {` (${projectTotals.value_total != 0 ? numeral((projectTotals.value_completed / projectTotals.value_total) * 100).format('0,0') : 0}%)`}
          </div>

          <div style={styles.total}>
            <div style={styles.label}>Remaining</div>
            <div style={styles.value}>
              {`${projectTotals.value_remaining < 0 ? '-' : ''}£${numeral(projectTotals.value_remaining * (projectTotals.value_remaining < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            {` (${projectTotals.value_total != 0 ? numeral((projectTotals.value_remaining / projectTotals.value_total) * 100).format('0,0') : 0}%)`}
          </div>

          <div style={styles.total}>
            <div style={styles.label}>Income</div>
            <div style={styles.value}>
              {`${projectTotals.income < 0 ? '-' : ''}£${numeral(projectTotals.income * (projectTotals.income < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            {` (${projectTotals.value_completed != 0 ? numeral((projectTotals.income / projectTotals.value_completed) * 100).format('0,0') : 0}%)`}
          </div>

        </div>

        <div style={styles.row}>

          <div style={styles.total}>
            <div style={styles.label}>Sales</div>
            <div style={styles.value}>
              {`${projectTotals.sales_total < 0 ? '-' : ''}£${numeral(projectTotals.sales_total * (projectTotals.sales_total < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            &nbsp;
          </div>

          <div style={styles.total}>
            <div style={styles.label}>Labour</div>
            <div style={styles.value}>
              {`${projectTotals.sales_labour < 0 ? '-' : ''}£${numeral(projectTotals.sales_labour * (projectTotals.sales_labour < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            {` (${projectTotals.sales_total != 0 ? numeral((projectTotals.sales_labour / projectTotals.sales_total) * 100).format('0,0') : 0}%)`}
          </div>

          <div style={styles.total}>
            <div style={styles.label}>Materials</div>
            <div style={styles.value}>
              {`${projectTotals.sales_materials < 0 ? '-' : ''}£${numeral(projectTotals.sales_materials * (projectTotals.sales_materials < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            {` (${projectTotals.sales_total != 0 ? numeral((projectTotals.sales_materials / projectTotals.sales_total) * 100).format('0,0') : 0}%)`}
          </div>

          <div style={styles.total}>
            <div style={styles.label}>Equipment</div>
            <div style={styles.value}>
              {`${projectTotals.sales_equipment < 0 ? '-' : ''}£${numeral(projectTotals.sales_equipment * (projectTotals.sales_equipment < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            {` (${projectTotals.sales_total != 0 ? numeral((projectTotals.sales_equipment / projectTotals.sales_total) * 100).format('0,0') : 0}%)`}
          </div>

        </div>

        <div style={styles.row}>

          <div style={styles.total}>
            <div style={styles.label}>P&L</div>
            <div style={styles.value}>
              {` ${projectTotals.profit_and_loss < 0 ? '-' : ''}£${numeral(projectTotals.profit_and_loss * (projectTotals.profit_and_loss < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            {` (${projectTotals.value_completed != 0 ? numeral((projectTotals.profit_and_loss / projectTotals.value_completed) * 100).format('0,0.[00]') : 0}%)`}
            &nbsp;
          </div>

        </div>

      </div>

    )
  }
}
