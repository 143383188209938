import { getPlanner } from '../../../HttpRequests/planner'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/fetching'
import getParams from '../Utils/getParams'
import handleException from '../../../Core/Helpers/handleException'
import { loadProjectTotals } from '../State/projectTotals';
import { getProjectTotals } from '../../../HttpRequests/projects';
import {omit} from "lodash";

export default () => async (dispatch, getState) => {

  const { controls } = getState().screens.Planner

  const params = getParams(controls)

  dispatch(startFetching())

  try {

    const response = await getPlanner({ params })

    dispatch(setData(response.data))

    let projectIdArray = [];
    for(let pc=0; pc < response.data.rows.length; pc++){
      projectIdArray.push(response.data.rows[pc].id);
    }

    const totalParams = {};

    totalParams['filter[profit_and_loss]'] = 1;
    totalParams['filter[id]'] = projectIdArray;

    const getProjectTotalsResponse = await getProjectTotals({ params: omit(totalParams, []) });
    dispatch(loadProjectTotals(getProjectTotalsResponse.data.data));

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
