import { createAction, handleActions } from 'redux-actions';

const loadProjectTotals = createAction("SCREENS/PLANNER/LOAD_PROJECT_TOTALS");

const defaultState = {
  orders_total_last_month: 0,
  valuations_total_last_month: 0,
  orders_total_this_month: 0,
  valuations_total_this_month: 0,
  total_orders_remaining: 0
}

const reducer = handleActions(
  {
    [loadProjectTotals]: (state, action) => action.payload
  },
  defaultState
);

export { loadProjectTotals, reducer };
