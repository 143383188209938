import { connect } from 'react-redux'
import Planner from './Planner'

import componentDidMount from './Handlers/componentDidMount'
import onCloseControlsModal from './Handlers/onCloseControlsModal'
import onCloseProjectEditorModal from './Handlers/onCloseProjectEditorModal'
import onCloseProjectCellModal from './Handlers/onCloseProjectCellModal'
import onCloseSubcontractorEditorModal from './Handlers/onCloseSubcontractorEditorModal'
import onCloseSubcontractorCellModal from './Handlers/onCloseSubcontractorCellModal'
import onCloseSubcontractorDrawer from './Handlers/onCloseSubcontractorDrawer'
import onKeyDown from './Handlers/onKeyDown'
import onKeyUp from './Handlers/onKeyUp'
import onHorizontalScroll from './Handlers/onHorizontalScroll'

const mapStateToProps = (state) => ({
  yAxis: state.screens.Planner.controls.yAxis,
  summary: state.screens.Planner.controls.summary,
  shrink: state.screens.Planner.controls.shrink,
  controls: state.screens.Planner.controls,
  ganttSettingDuration: state.screens.Planner.controls.ganttSettingDuration,
  controlsModal: state.screens.Planner.controlsModal,
  projectEditorModal: state.screens.Planner.projectEditorModal,
  projectCellModal: state.screens.Planner.projectCellModal,
  subcontractorEditorModal: state.screens.Planner.subcontractorEditorModal,
  subcontractorCellModal: state.screens.Planner.subcontractorCellModal,
  subcontractorDrawer: state.screens.Planner.subcontractorDrawer,
  projectTotals: state.screens.Planner.projectTotals,
  showSubcontractorModal: state.screens.Planner.showSubcontractorModal,

})

const actionCreators = {
  componentDidMount,
  onCloseControlsModal,
  onCloseProjectEditorModal,
  onCloseProjectCellModal,
  onCloseSubcontractorEditorModal,
  onCloseSubcontractorCellModal,
  onCloseSubcontractorDrawer,
  onKeyDown,
  onKeyUp,
  onHorizontalScroll,
}

export default connect(mapStateToProps, actionCreators)(Planner)
