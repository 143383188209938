import {Task} from "gantt-task-react";
import projectPhases from '../../Utils/projectPhases'
import {Tag} from "antd";
import React from "react";
import numeral from 'numeral';

export function initTasks(rows) {

    console.log("G ROWS:",rows);
    let tasks: Task[] = [];

    if (rows.length > 0)
    {
        for (let rowc = 0; rowc < rows.length; rowc++) {

            let nameAr = [];
            let record = rows[rowc];
            nameAr.push("Project " + record.number);
            nameAr.push("Title " + record.number);
            nameAr.push("Phase " + record.phase.title);

            if (record.start_date && record.handover_date) {

                let thisTask = {
                    start: new Date(record.start_date),
                    end: new Date(record.handover_date),
                    name: nameAr.join(", "),
                    id: "Project_" + record.id,
                    type: 'project',
                    progress: 100,
                    hideChildren: true,
                    styles: {progressColor: projectPhases[record.phase.key], progressSelectedColor: projectPhases[record.phase.key]},
                    project: record,
                    dependencies: []
                };
                tasks.push(thisTask);

                for (let orderc = 0; orderc < record.orders.length; orderc++) {
                    let orderRecord = record.orders[orderc];
                    let orderTask = {
                        start: new Date(orderRecord.date),
                        end: new Date(orderRecord.date),
                        name: "Order: " + orderRecord.reference + ", Total:£" + orderRecord.total,
                        id: "p_" + orderRecord.project_id + "_order_" + orderRecord.id,
                        type: 'milestone',
                        subType: 'order',
                        project: "Project_" + orderRecord.project_id,
                        progress: 0,
                        hideChildren: true,
                        order: orderRecord,
                        subcontractor: [],
                        dependencies: [],
                        dependenciesObject: [],
                        jobs: [],
                        styles: { progressColor: '#54eeff', progressSelectedColor: '#5499ff' },

                    };
                    tasks.push(orderTask);
                }

                for (let invoicec = 0; invoicec < record.invoices.length; invoicec++) {
                    let invoiceRecord = record.invoices[invoicec];
                    let progress = 0;
                    if (invoiceRecord.added_to_quickbooks)
                        progress = 100;
                    let invoiceTask = {
                        start: new Date(invoiceRecord.date),
                        end: new Date(invoiceRecord.date),
                        name: "Invoice: " + invoiceRecord.reference + ", Total:£" + invoiceRecord.total,
                        id: "p_" + invoiceRecord.project_id + "_invoice_" + invoiceRecord.id,
                        type: 'milestone',
                        subType: 'invoice',
                        project: "Project_" + invoiceRecord.project_id,
                        progress: 100,
                        hideChildren: true,
                        invoice: invoiceRecord,
                        subcontractor: [],
                        dependencies: [],
                        dependenciesObject: [],
                        jobs: [],
                        styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
                    };
                    tasks.push(invoiceTask);
                }

                for (let estimatec = 0; estimatec < record.estimates.length; estimatec++) {
                    let estimateRecord = record.estimates[estimatec];
                    let estimateTask = {
                        start: new Date(estimateRecord.date),
                        end: new Date(estimateRecord.date),
                        name: "Estimate: " + estimateRecord.title + ", Total:£" + estimateRecord.total,
                        id: "p_" + estimateRecord.project_id + "_estimate_" + estimateRecord.id,
                        type: 'milestone',
                        subType: 'estimate',
                        project: "Project_" + estimateRecord.project_id,
                        progress: 100,
                        hideChildren: true,
                        estimate: estimateRecord,
                        subcontractor: [],
                        dependencies: [],
                        dependenciesObject: [],
                        jobs: [],
                        styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },

                    };
                    tasks.push(estimateTask);
                }


                let subbyTasks = [];
                for (let jobc = 0; jobc < record.jobs.length; jobc++) {
                    let jobrecord = record.jobs[jobc];

                    if (typeof subbyTasks[jobrecord.subcontractor_id] === 'undefined') {
                        let subbyTask = {
                            start: new Date(jobrecord.date),
                            end: new Date(jobrecord.date),
                            name: "Subcontractor:" + jobrecord.subcontractor.name,
                            id: "p_" + jobrecord.project_id + "_subby_" + jobrecord.subcontractor_id,
                            type: 'task',
                            subType: 'subby',
                            project: "Project_" + jobrecord.project_id,
                            progress: 0,
                            hideChildren: true,
                            subcontractor: jobrecord.subcontractor,
                            dependencies: [],
                            dependenciesObject: [],
                            jobs: [],
                        };
                        subbyTasks[jobrecord.subcontractor_id] = subbyTask;
                    }
console.log(jobrecord.date);
                    subbyTasks[jobrecord.subcontractor_id].end = new Date(jobrecord.date);
                    subbyTasks[jobrecord.subcontractor_id].jobs.push(jobrecord);

                    /*
                    // THIS REMOVES THE JOBS FROM THE VIEW
                    let jobTask = {
                        start: new Date(jobrecord.date),
                        end: new Date(jobrecord.date),
                        name: "Job:" + jobrecord.id,
                        id: "job_" + jobrecord.id,
                        type: 'task',
                        subType: 'job',
                        project: "Project_" + jobrecord.project_id,
                        progress: 100,
                        hideChildren: true,
                    };

                    subbyTasks[jobrecord.subcontractor_id].dependencies.push(jobTask.id);
                    subbyTasks[jobrecord.subcontractor_id].dependenciesObject.push(jobTask);
                    */

                }

                Object.keys(subbyTasks).forEach(function (skey) {
                    //console.log("AAA:", subbyTasks[skey]);
                    tasks.push(subbyTasks[skey]);
                    subbyTasks[skey].name += ", Total Jobs: " + subbyTasks[skey].jobs.length;
                    Object.keys(subbyTasks[skey].dependenciesObject).forEach(function (nkey) {
                        tasks.push(subbyTasks[skey].dependenciesObject[nkey]);
                    });
                });

                //console.log("--------------------------------------");
                //console.log(subbyTasks);
                //console.log(tasks);

            }
        }
        //console.log('tasks 1');
    }

    return tasks;

}

export function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;

    for (let i = 0; i < projectTasks.length; i++) {
        const task = projectTasks[i];
        if (start.getTime() > task.start.getTime()) {
            start = task.start;
        }
        if (end.getTime() < task.end.getTime()) {
            end = task.end;
        }
    }
    return [start, end];
}
