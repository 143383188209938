import { combineReducers } from 'redux';

import { reducer as client } from './State/client';
import { reducer as showClientModal } from './State/showClientModal';
import { reducer as showClientSiteModal } from './State/showClientSiteModal';
import { reducer as showClientContactModal } from './State/showClientContactModal';
import { reducer as projectTotals } from './State/projectTotals';

export default combineReducers({
  client,
  showClientModal,
  showClientSiteModal,
  showClientContactModal,
  projectTotals
})
